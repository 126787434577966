import React from 'react'
import PropTypes from 'prop-types'
import {Link, graphql} from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
import BlogRoll from '../components/BlogRoll'
import GamePreview from "../components/GamePreview";
import RegelBlogRoll from "../components/RegelBlogRoll";
import {IndexPageTemplate} from "./index-page";
import {getImage} from "gatsby-plugin-image";
import FullWidthImage from "../components/FullWidthImage";

export const PlaystationPageTemplate = ({
                                          image,
                                          title,
                                          subheading,
                                          intro,
                                        }) => {
  const heroImage = getImage(image) || image;
  return (
    <div>
      <FullWidthImage img={heroImage} title={title} subheading={subheading} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="content">
                    <div className="tile">
                      <h2 className="title">Hier kommen unsere besten Playstation Spiele zu zweit</h2>
                    </div>
                    <div className="tile">
                      <p className="subtitle subtitle-small justify-center">
                        Getestet haben wir die Spiele natürlich persönlich und ausgiebig bis zum
                        Spielende!
                        Bei manchen Spielen mussten wir uns aber auch durchbeißen oder haben schon
                        vorher aufgegeben.
                        Diese Spiele haben wir aber auch nicht aufgeführt da sie nicht wirklich zu
                        zweit spielbar sind.
                      </p>
                    </div>
                  </div>
                  <GamePreview gridItems={intro.blurbs}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

PlaystationPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const PlaystationPage = ({data}) => {
  const {frontmatter} = data.markdownRemark

  return (
    <Layout>
      <PlaystationPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

PlaystationPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default PlaystationPage

export const pageQuery = graphql`
  query PlaystationPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "playstation-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 64, layout: CONSTRAINED)
          }
        }
        subheading
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            alt
            title
            text
            link
            bloglink
          }
          heading
          description
        }
      }
    }
  }
`
